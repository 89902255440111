import React, { useEffect, createRef } from "react"
import LayoutTeam from "../components/layoutteam"
import Navigation from "../components/navigation"
import { useStaticQuery, graphql, Link } from "gatsby"
import GofloaterSearchForm from "../components/search/searchalter"
import "../styles/hybrid.scss"
import SEOHeader from "../components/seo-header"
import teamLogo from "../img/TeamLogo.svg"
import lottie from "lottie-web"
import SafeSpace from "../img/safety-badge.jpg"
import animation from "../animations/newhomeanimation.json"
import "../styles/coworking.scss"

export default function CoworkingSpaces() {
  const data = useStaticQuery(graphql`
    query CoworkingSpacepageQuery {
      allCities {
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }
    }
  `)
  const cities = data
  let animationContainer = createRef()
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => {
      animationContainer.current = animationContainer.current || {}
      anim.destroy(animationContainer.current)
    } // optional clean up for unmounting
  }, [animationContainer])
  return (
    <div>
      <SEOHeader
        title="Book day passes at best coworking space across India - GoFloaters"
        description="Explore and book day passes at top coworking spaces across India instantly. Get access to fully equipped, safe and verified spaces and work near home."
        socialURL="https://assets.gofloaters.com/socialimage/coworking-spaces.jpg"
        pinterest="true"
      ></SEOHeader>

      <LayoutTeam>
        <div className="coworkingSpaces">
          <div>
            <Navigation />
            <section className="banner">
              <div className="container">
                <div className="row aligner">
                  <div className="col-md-6 coworkingSpacesSearch">
                    <h1>Find a coworking space</h1>
                    <p className="subHeading">
                      Coworking Spaces for every size | All inclusive pricing
                    </p>
                    <GofloaterSearchForm
                      placeholder="Search by location"
                      spacetype="dailyofficeSpace"
                    />
                    <br></br>
                    <br></br>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4 col-6">
                          <h2>40+</h2>
                          <p className="subText">Cities we operate in</p>
                        </div>
                        <div className="col-md-4 col-6">
                          <h2>3000+</h2>
                          <p className="subText">Curated Workspaces</p>
                        </div>
                        <div className="col-md-4 col-6">
                          <h2>30,000+</h2>
                          <p className="subText">Customers booking monthly</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 DesktopOnly">
                    <img
                      src="https://assets.gofloaters.com/coworking/coworking-space.png"
                      className="img-responsive"
                      alt="Explore best shared office space across India - GoFloaters"
                    ></img>
                  </div>
                  <div className="col-md-6 MobileOnly">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <b>Our Coworking Cities</b>
                          </p>
                        </div>

                        {cities.allCities.edges.map(city => {
                          return (
                            <div className="col-md-2 col-4 city-icons">
                              <Link
                                to={"/coworking-spaces/" + city.node.slug + "/"}
                              >
                                <img
                                  src={
                                    "https://assets.gofloaters.com/coworking/" +
                                    city.node.slug +
                                    ".svg"
                                  }
                                  alt={city.node.displayCity}
                                ></img>
                                <br></br>
                                {city.node.displayCity}
                              </Link>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="padding60 DesktopOnly">
          <div className="container">
            <div className="row">
              {cities.allCities.edges.map(city => {
                return (
                  <div className="col-md-2 col-4 city-icons">
                    <Link to={"/coworking-spaces/" + city.node.slug + "/"}>
                      <img
                        src={
                          "https://assets.gofloaters.com/coworking/" +
                          city.node.slug +
                          ".svg"
                        }
                        alt={city.node.displayCity}
                      ></img>
                      <br></br>
                      {city.node.displayCity}
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="padding60">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    lineHeight: "1.3",
                  }}
                >
                  Coworking Amenities
                </h2>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/hi_speed_wifi.png"
                        alt="High Speed WiFi"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>High Speed WiFi</h3>
                      <p>
                        High-Speed WiFi to get you going the moment you enter.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/power_backup.png"
                        alt="Power Backup"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Power Backup</h3>
                      <p>
                        Power backup to ensure that you are able to work
                        uninterrupted.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/ac.png"
                        alt="AC"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>AC</h3>
                      <p>
                        Climate controlled environment with cooling set
                        appropriately to make your work pleasurable
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/free_parking.png"
                        alt="Parking"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Parking</h3>
                      <p>Free parking inside and near the space.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/coffee_tea.png"
                        alt="Coffee / Tea"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Tea & Coffee</h3>
                      <p>
                        Recharge yourself with a coffee or your favorite
                        beverage when needed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/printer.png"
                        alt="printer"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Printer</h3>
                      <p>
                        Printing and scanning facilities available on request.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                {" "}
                <br></br>
                <p style={{ fontSize: "12px" }}>
                  * Check the space details page for each space to explore the
                  amenities available at that space
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    lineHeight: "1.3",
                  }}
                >
                  The right coworking solution for
                </h2>
              </div>
              <div className="col-md-4 CoworkingimageBox">
                <img
                  src="https://assets.gofloaters.com/coworking/remote-team.jpg"
                  alt="Remote Teams"
                ></img>
                <h4>Remote Teams</h4>
              </div>
              <div className="col-md-4 CoworkingimageBox">
                <img
                  src="https://assets.gofloaters.com/coworking/startups.jpg"
                  alt="Startups"
                ></img>
                <h4>Startups</h4>
              </div>
              <div className="col-md-4 CoworkingimageBox">
                <img
                  src="https://assets.gofloaters.com/coworking/freelancer.jpg"
                  alt="Freelancers"
                ></img>
                <h4>Freelancers</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 style={{ fontWeight: "bold" }}>
                Best Coworking Spaces Near Me
              </h4>
              <p>
                Are you a freelancer or an independent professional? Do you own
                a startup or a small/medium business or part of a large
                enterprise? We know exactly what you need when it comes to an
                office. We&rsquo;ve done all the work for you! We&rsquo;ve
                handpicked office spaces in your city - spaces that come with
                fast Wi-Fi, well-designed interiors and ample plug points. If
                you have just typed &ldquo;coworking spaces near me&rdquo; and
                landed at this page, then congratulations, you are at the right
                place. Here you can find a huge variety of coworking spaces,
                office spaces for different team sizes and meeting spaces that
                suit your every meeting need.
              </p>
              <br />
              <h4 style={{ fontWeight: "bold" }}>What is a Coworking Space?</h4>
              <p>
                The origins of coworking spaces can be attributed to the hacker
                spaces that came up in cities like Berlin, San Francisco and
                Brooklyn in 1995. In 1995 the word&nbsp; &ldquo;coworking&rdquo;
                was first used by Bernard DeKoven, who described it as
                &ldquo;working together as equals.&rdquo;&nbsp; Coworking spaces
                offer individuals and companies the flexibility of access to
                work spaces that have all the amenities of a traditional office
                and sometimes more on a simple rental model. The biggest
                advantage of coworking spaces is the networking opportunities.
                Coworking spaces on GoFloaters platform are available on day
                basis rentals where you just pay-per-use as per your need. You
                don&rsquo;t sign any contracts or lock-in your money on security
                deposits.&nbsp;
              </p>
              <br />
              <h4 style={{ fontWeight: "bold" }}>
                What are the benefits of shared office spaces and coworking
                spaces?
              </h4>

              <p>
                Traditional offices burden a business with high rents, hidden
                charges and high lock in periods. These have given way for
                stylish, contemporary and functional shared office spaces and
                coworking spaces. Whether you are a freelancer, startup or a
                service company your choice of workspace in 2022 should be
                coworking spaces or shared office spaces. Here are the top
                benefits that you get when you choose either of the two :&nbsp;
              </p>
              <br />
              <ul>
                <li>
                  <strong>Flexibility</strong> : No long term contracts that
                  block you{" "}
                </li>
                <li>
                  <strong>Cost Effective</strong> : You pay one simple and
                  affordable rent for the space and all the amenities{" "}
                </li>
                <li>
                  <strong>Pay-per-use</strong> : This is something unique to
                  GoFloaters where we have made the choicest of spaces available
                  on daily and hourly rentals.&nbsp;
                </li>
                <li>
                  <strong>Location choice</strong> : With thousands of options
                  available you can get a work space where you need it{" "}
                </li>
                <li>
                  <strong>Community</strong> : Coworking spaces are the breeding
                  ground for strong communities of entrepreneurs and freelancers{" "}
                </li>
              </ul>
              <br />
              <h4 style={{ fontWeight: "bold" }}>
                Why should I use the GoFloaters app to book?
              </h4>
              <p>
                GoFloaters has been in the space of helping individuals and
                teams find flexible and affordable workspaces for over 3 years
                now. GoFloaters was started with a vision to help anyone get an
                office space when they want, where they want and within their
                budget. We set out to build office spaces for a distributed
                world where individuals and teams should be able to work near
                home.&nbsp;
              </p>
              <br />
              <p>
                Following are the advantages of booking coworking spaces and
                meeting spaces on the GoFloaters app:
              </p>
              <ul>
                <li>
                  <strong>Work Near Home </strong>: In most of the cities that
                  we serve you can get a space within 5 kms of your home or
                  wherever you are{" "}
                </li>
                <li>
                  <strong>Pay-per-use</strong> : You can book coworking spaces
                  on a daily basis and meeting spaces on an hourly basis and pay
                  only for the time you have used it.{" "}
                </li>
                <li>
                  <strong>No contracts </strong>: You don&rsquo;t have to sign
                  any kind of contracts with us.&nbsp;
                </li>
                <li>
                  <strong>Cost effective</strong> : We have negotiated heavily
                  with our space partners to bring you savings of at least 20%
                  if not more on the day pass rates and the meeting room rents.{" "}
                </li>
                <li>
                  <strong>Instant Bookings</strong> : No need to call anyone or
                  email anyone to check space availability. You can book
                  coworking spaces instantaneously. Meeting spaces just need 30
                  mins of time for confirmation.&nbsp;&nbsp;
                </li>
                <li>
                  <strong>Community Perks</strong> : GoFloaters has partnered
                  with over 65+ companies to bring you over Rs 1 Crore of
                  benefits to you.{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="padding60 brandBackground">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    lineHeight: "1.3",
                    color: "#fff",
                  }}
                >
                  GoFloaters Advantage
                </h2>
              </div>
              <div className="container roundWhiteBackground">
                <div className="row">
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/pay-you-go.svg"
                        alt="Pay as you use"
                      ></img>
                      <p>Pay as you use</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/largest-network.svg"
                        alt="Largest Network"
                      ></img>{" "}
                      <p>Largest Network</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/affordable.svg"
                        alt="Affordable"
                      ></img>{" "}
                      <p>Affordable</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/work-near-home.svg"
                        alt="Work near home"
                      ></img>{" "}
                      <p>Work near home</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/no-contract.svg"
                        alt="No contracts"
                      ></img>{" "}
                      <p>No contracts</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/partner-benefits.svg"
                        alt="Partner benefits"
                      ></img>{" "}
                      <p>Partner benefits</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="TeamHomeBanner">
          <div className="container">
            <div className="row teamPadding">
              <div className="col-md-4 TeamHomeBannerleft">
                <h2 style={{ fontWeight: "bold", lineHeight: "1.3" }}>
                  Introducing<br></br>GoFloaters for Teams
                </h2>
                <br></br>
                <img src={teamLogo} width="250"></img>
                <br></br> <br></br>
                <p>
                  Save more than 60% from your current office rental contract by
                  offering pay-per-use office space for your remote team.
                </p>
                <Link to="/teams/" className="searchWorkspace ">
                  Show me how
                </Link>
                <br></br>
                <br></br>
              </div>
              <div className="col-md-6">
                <div className="GoflaoterHomeRight">
                  <div className="row">
                    <div className="col-md-8">
                      <h4>SIGN UP</h4>
                      <p>
                        Sign up on our platform within 3 minutes. No credit card
                        required.
                      </p>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-8">
                      <h4>CHOOSE WORKSPACES</h4>
                      <p>
                        Choose your workspaces among 3000+ high quality
                        workspaces across the country.
                      </p>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-8">
                      <h4>PAY-PER USE</h4>
                      <p>
                        Set your monthly budget and pay only when your employees
                        use our spaces. No contracts or upfront payments
                        involved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 introImage">
                <div className="animation-container" ref={animationContainer} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="text-center" style={{ padding: "30px 0px" }}>
          <h1 style={{ color: "#000", fontWeight: "bold" }}>
            Covid-19 Safety Measures for Coworking Spaces
          </h1>
        </div> */}
        {/* <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 text-center">
              <p>
                GoFloaters cares about your well being and wants to ensure that
                you feel safe while working out of our spaces. That’s why we’ve
                developed the Safety Badge program along with our partner
                spaces.
              </p>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3"></div>
            <div className="col-md-6 text-center">
              <br></br>
              <p className="text-center">
                The program is a voluntary pledge that our spaces take to ensure
                that their space meets our requirements for health and safety
                standards.
              </p>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div
            className="row"
            style={{
              border: "1px solid #d2dcf0",
              padding: "15px 20px",
              margin: "50px",
              borderRadius: "8px",
            }}
          >
            <div className="col-md-12 text-center">
              <h3>Safety Badge Requirements</h3>
              <p style={{ color: "#303030" }}>
                Required for Verified COVID Safety Badge
              </p>
              <br></br>
            </div>
            <div className="col-md-12 covid-response MobileOnly">
              <ul>
                <li>Mask Required</li>
                <li>Hand Sanitizer Provided</li>
                <li>Social Distancing between Workstations</li>
                <li>Cleaning and disinfection of Common Surfaces</li>
                <li>Safe Food Preparation and Pantry Services</li>
                <li>Visitor Records for Contact Tracing</li>
              </ul>
            </div>
            <div className="col-md-6 covid-response DesktopOnly">
              <ul>
                <li>Mask Required</li>
                <li>Hand Sanitizer Provided</li>
                <li>Social Distancing between Workstations</li>
              </ul>
            </div>
            <div className="col-md-6 covid-response DesktopOnly">
              <ul>
                <li>Cleaning and disinfection of Common Surfaces</li>
                <li>Safe Food Preparation and Pantry Services</li>
                <li>Visitor Records for Contact Tracing</li>
              </ul>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: "15px 20px",
              margin: "40px",
            }}
          >
            <div className="col-md-6">
              <br></br>
              <h3>Identifying Safe Spaces</h3>
              <p>
                GoFloaters Safe Space badge will appear next to spaces that have
                verified safety measures undertaken and have met our safety
                standards.
              </p>
            </div>
            <div className="col-md-6 text-center">
              <img
                src={SafeSpace}
                style={{ width: "200px" }}
                alt="GoFloaters Verified Safe"
              ></img>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: "15px 20px",
              margin: "40px",
            }}
          ></div>
        </div> */}
        {/* <div className="" id="unlockDeals">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>Unlock exclusive deals when you sign up</h2>
                <p>
                  We offer ₹1.5 Crores in free benefits and discounts for
                  eligible startups* through our partner program
                </p>
              </div>
            </div>
            <div className="row MobileOnly">
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/todoist.svg"
                    className="lazyload height"
                    alt="todoist"
                    width="108"
                    height="30"
                  ></img>
                  <p>1 free year of Business plan</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/miro.svg"
                    className="lazyload width"
                    alt="miro"
                    width="90"
                    height="32"
                  ></img>
                  <p>$1000 in credits</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/aws.png"
                    className="lazyload height"
                    alt="aws"
                    width="50"
                    height="30"
                  ></img>
                  <p>$1000 credit for 2 years</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/razorpay.png"
                    className="lazyload width"
                    alt="razorpay"
                    width="90"
                    height="19"
                  ></img>
                  <p>Zero transaction fee upto ₹4L</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/hubspot.png"
                    className="lazyload height"
                    alt="hubspot"
                    width="103"
                    height="30"
                  ></img>
                  <p>90% off on yearly plans</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/udemy.png"
                    className="lazyload height"
                    alt="udemy"
                    width="103"
                    height="30"
                  ></img>
                  <p>Upto 95% off on courses</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/segment.svg"
                    className="lazyload height"
                    alt="segment"
                    width="108"
                    height="30"
                  ></img>
                  <p>
                    $50,000 in credits + discounts on 35+ products worth $1M
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/typeform.png"
                    className="lazyload height"
                    alt="typeform"
                    width="47"
                    height="30"
                  ></img>
                  <p>50% of monthly & yearly plans</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/kaleyra.png"
                    className="lazyload height"
                    alt="kaleyra"
                    width="124"
                    height="30"
                  ></img>
                  <p>₹8000 credit for 1 year</p>
                </div>
              </div>
            </div>
            <div className="row gofloaterHomePartner DesktopOnly">
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <div className="column1 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/todoist.svg"
                    className="lazyload height"
                    alt="todoist"
                    width="108"
                    height="30"
                  ></img>
                  <p>1 free year of Business plan</p>
                </div>
              </div>
              <div className="col-md-2 columns2">
                <div className=" column2 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/miro.svg"
                    className="lazyload width"
                    alt="miro"
                    width="90"
                    height="32"
                  ></img>
                  <p>$1000 in credits</p>
                </div>
                <div className="column2 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/aws.png"
                    className="lazyload height"
                    alt="aws"
                    width="50"
                    height="30"
                  ></img>
                  <p>$1000 credit for 2 years</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/razorpay.png"
                    className="lazyload width"
                    alt="razorpay"
                    width="90"
                    height="19"
                  ></img>
                  <p>Zero transaction fee upto ₹4L</p>
                </div>
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/hubspot.png"
                    className="lazyload height"
                    alt="hubspot"
                    width="103"
                    height="30"
                  ></img>
                  <p>90% off on yearly plans</p>
                </div>
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/udemy.png"
                    className="lazyload height"
                    alt="udemy"
                    width="103"
                    height="30"
                  ></img>
                  <p>Upto 95% off on courses</p>
                </div>
              </div>
              <div className="col-md-2 columns2">
                <div className=" column4 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/segment.svg"
                    className="lazyload height"
                    alt="segment"
                    width="108"
                    height="30"
                  ></img>
                  <p>
                    $50,000 in credits + discounts on 35+ products worth $1M
                  </p>
                </div>
                <div className="column4 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/typeform.png"
                    className="lazyload height"
                    alt="typeform"
                    width="47"
                    height="30"
                  ></img>
                  <p>50% of monthly & yearly plans</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="column5 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/kaleyra.png"
                    className="lazyload height"
                    alt="kaleyra"
                    width="124"
                    height="30"
                  ></img>
                  <p>₹8000 credit for 1 year</p>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <p>&nbsp;</p>
                <Link
                  to="/our-community-perks/"
                  title="Our Community Perks"
                  style={{ color: "#303030", fontWeight: "bold" }}
                >
                  ...and many more
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className="padding-60">
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="container">
                  <div className="row ">
                    <div className="col-md-12 nocolpadding">
                      <p>Trusted by 10,000+ customers including</p>
                    </div>
                    <br></br>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding noLeftPadding">
                      <img
                        src="https://assets.gofloaters.com/teams/decalthaonlogo.svg"
                        alt="Decalthaon"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        src="https://assets.gofloaters.com/teams/indusind.png"
                        alt="Indusind"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        src="https://assets.gofloaters.com/teams/nippon.png"
                        alt="Nippon"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        src="https://assets.gofloaters.com/teams/homelane.png"
                        alt="Homelane"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        src="https://assets.gofloaters.com/clients/shriram.png"
                        alt="Shriram"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        src="https://assets.gofloaters.com/clients/notion-press.png"
                        alt="Notion Press"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        src="https://assets.gofloaters.com/teams/mind-mom.png"
                        alt="Fusion"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        src="https://assets.gofloaters.com/teams/think-music.jpg"
                        alt="Think Music"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8"></div>
            </div>
          </div>
        </div>
      </LayoutTeam>
    </div>
  )
}
